















































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {CategoryService} from "@/store/category/CategoryService";
import {CategoryType} from "@/models/category/CategoryType";
import {Category} from '@/models/category/category';
import {SearchResult} from "@/models/SearchResult";
import {Activity} from "@/models/customer/activity/Activity";
import {ValidationProvider, ValidationObserver, extend} from 'vee-validate';
import vSelect from 'vue-select';
import i18n from "@/i18n";
import {required} from 'vee-validate/dist/rules';
import {ActivityService} from "@/services/customer/ActivityService";
import {ActionResult} from "@/models/ActionResult";
// import {map, debounce, each} from 'lodash'
import map from 'lodash/map';
import each from 'lodash/each';
import debounce from 'lodash/debounce';
import CustomerProjectListService from "@/views/customer/project/CustomerProjectListService";

extend('required', {
    ...required,
    message: (_: string, values: any) => i18n.t('validations.required', [_])
} as any);

@Component({
    components: {
        'v-select': vSelect,
        ValidationProvider,
        ValidationObserver
    }
})

export default class ActivityQuickInsert extends Vue {
    @Prop({default: () => []}) types: any[];
    activePrompt: boolean = false;
    typeSelected: any[] = [];
    backgroundLoading: string = 'primary';
    colorLoading: string = '#fff';
    activity: Activity;
    invalid = false;
    listType: any[] = [];
    projectSelected: any[] = [];
    listProject: any[] = [];
    debounce: any;

    private categoryService = new CategoryService();
    private activityService = new ActivityService();
    private projectService = new CustomerProjectListService();

    constructor() {
        super();
        this.activity = new Activity();
    }

    @Watch('types')
    getType(value: any[]) {
        this.listType = value;
    }

    show() {
        this.activePrompt = true;
        this.typeSelected = [];
        if (!this.listType || this.listType.length === 0) {
            this.getCategoryType();
        }
    }

    save() {
        this.activity.customerId = this.$route.params.id;
        this.activity.types = JSON.stringify(map(this.typeSelected, (item: any) => {
            return {Id: item.id, Name: item.name}
        }));

        let projectId = ';';
        if (this.projectSelected && this.projectSelected.length > 0) {
            each(this.projectSelected, (item: any) => {
                projectId += `${item.id};`;
            });
        }
        this.activity.projectId = projectId;
        this.activity.projects = map(this.projectSelected, (item: any) => {
            return {projectId: item.id, projectName: item.name}
        })

        this.$vs.loading({
            background: this.backgroundLoading,
            color: this.colorLoading,
            container: "#btnSave",
            scale: 0.45
        })

        this.invalid = false;
        this.activityService.insert(this.activity).then((result: ActionResult<number>) => {
            this.$vs.notify({
                title: "Thông báo", text: result.message, color: (result.code > 0 ? 'primary' : 'danger'),
                iconPack: 'feather', icon: 'icon-info'
            });

            this.$vs.loading.close('#btnSave > .con-vs-loading')

            if (result.code > 0) {
                this.activePrompt = false;
                this.activity = new Activity();
                this.$emit('saveSuccess', result.data);
            }
        }).catch((e) => {
            this.$vs.notify({
                title: '',
                text: e.message,
                color: 'danger'
            });
            this.invalid = false;
        });
    }

    fetchOptions(search: string, loading: any) {
        if (this.debounce) {
            this.debounce.cancel();
        }

        this.debounce = debounce(() => {
            this.searchProject(search, loading);
        }, 500);

        this.debounce();
    }

    focus(search: string) {
        this.searchProject(search, null);
    }

    private async searchProject(search: string, loading: any | null) {
        if (loading) {
            loading(true);
        }
        this.projectService.get(search, 1, 20)
            .finally(() => {
                if (loading) {
                    loading(false);
                }
            }).then((result: SearchResult<any>) => {
            this.listProject = result.items;
        })
    }

    private async getCategoryType() {
        this.categoryService.getByType(CategoryType.TransactionType).then((result: SearchResult<Category>) => {
            // this.types = result.items;
            this.listType = map(result.items, (item: Category) => {
                return {id: item.idNumber, name: item.name ? item.name : ''}
            });
        })
    }

    private showLoading(isLoading: boolean = true) {
        if (isLoading) {
            this.$vs.loading();
        } else {
            this.$vs.loading.close();
        }
    }
}
